<template>
    <h3>이곳은 제 프로젝트를 소개하는 페이지 입니다.</h3>
</template>

<script>
export default {
    name: 'nowproject-page'
}
</script>

<style></style>