<template>
    <p class="copyright">
        (c)2024 hongjun.kr
    </p>
</template>

<script>
export default {
    name: 'footer-bar'
}
</script>

<style>
.copyright {
    width: 100%;
    margin: 20px 0px;
    text-align: center;
}
</style>