import { createWebHistory, createRouter } from "vue-router";
import Error404 from "./components/pages/404.vue";
import Main from "./components/pages/main.vue";
import Nowproject from "./components/pages/nowproject.vue";

const routes = [
  {
    path: "/:dltkdgkswnth",
    component: Error404,
  },
  {
    path: "/",
    component: Main,
  },
  {
    path: "/nowproject",
    component: Nowproject,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
