<template>
    <div class="content-view">
        <h4>내일의 내가 오늘의 나보다 더 나은사람이 되도록</h4>
        <span>다양한 배움과 살아가면서 깨달은 내용들을 정리해둔 hongjun.kr 입니다.</span>
        <div class="section nowproject">
            <p class="section-title">
                현재 진행중인 프로젝트
            </p>
            <div class="cards overflow-x-auto">
                <div class="card" v-for="(a, i) in np_title" :key="i">
                    <img src="https://picsum.photos/400/200" class="card-img-top">
                    <div class="card-body">
                        <h5 class="card-title">{{ a }}</h5>
                        <p class="card-text">{{ np_subtitle[i] }}</p>
                        <a v-bind:href="np_url[i]" class="btn btn-primary">더 알아보기</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="section project">
            <p class="section-title">
                완료된 프로젝트
            </p>
            <div class="cards overflow-x-auto">
                <div class="card" v-for="(a, i) in p_title" :key="i">
                    <img src="https://picsum.photos/400/200" class="card-img-top">
                    <div class="card-body">
                        <h5 class="card-title">{{ a }}</h5>
                        <p class="card-text">{{ p_subtitle[i] }}</p>
                        <a v-bind:href="p_url[i]" class="btn btn-primary">더 알아보기</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="section project">
            <p class="section-title">
                끄적글적(블로그)
            </p>
            <div class="cards overflow-x-auto">
                <div class="card" v-for="(a, i) in blog_title" :key="i">
                    <img src="https://picsum.photos/400/200" class="card-img-top">
                    <div class="card-body">
                        <h5 class="card-title">{{ a }}</h5>
                        <p class="card-text">{{ blog_subtitle[i] }}</p>
                        <a v-bind:href="blog_url[i]" class="btn btn-primary">더 알아보기</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'main-page',
    data() {
        return {
            np_title: ['현재진행중1', '현재진행중2', '현재진행중3', '현재진행중4'],
            np_subtitle: ['현재진행중1에 대한 설명이에요!', '현재진행중2에 대한 설명이에요!', '현재진행중3에 대한 설명이에요!', '현재진행중4에 대한 설명이에요!'],
            np_url: ['#', '#', '#', '#'],
            p_title: ['완료된프로젝트1', '완료된프로젝트2', '완료된프로젝트3', '완료된프로젝트4'],
            p_subtitle: ['완료된프로젝트1에 대한 설명이에요!', '완료된프로젝트2에 대한 설명이에요!', '완료된프로젝트3에 대한 설명이에요!', '완료된프로젝트4에 대한 설명이에요!'],
            p_url: ['#', '#', '#', '#'],
            blog_title: ['블로그내용1', '블로그내용2', '블로그내용3', '블로그내용4'],
            blog_subtitle: ['블로그내용1에 대한 설명이에요!', '블로그내용2에 대한 설명이에요!', '블로그내용3에 대한 설명이에요!', '블로그내용4에 대한 설명이에요!'],
            blog_url: ['#', '#', '#', '#'],
        }
    },
}
</script>

<style>
.section {
    margin: 20px 0px;
    padding: 15px 0px 20px 0px;
    border-bottom: 1px solid #bbb;
}

.section-title {
    font-size: 20px;
    font-weight: bold;
}

.cards {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.card {
    min-width: 250px;
    margin-right: 20px;
}

.end-card {
    min-width: 250px;
}
</style>