<template>
    <nav class="position-fixed top-0 navbar navbar-expand-lg bg-body-tertiary" style="width: 100%; z-index: 1;">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">hongjun.kr</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="/">메인화면</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/nowproject">진행중인 프로젝트</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/project">완료된 프로젝트</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/blog">끄적글적</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link disabled" aria-disabled="true">Disabled</a>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'navi-bar',
}
</script>

<style>
.navbar {}
</style>