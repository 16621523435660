<template>
  <Navibar />
  <div class="view">
    <router-view></router-view>
  </div>
  <Footer />
</template>
<script>
import Navibar from './components/ui/navbar.vue'
import Footer from './components/ui/footer.vue'
export default {
  name: 'App',
  components: {
    Navibar,
    Footer,
  }
}
</script>

<style>
#app {
  margin: 0px;
}

.view {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content-view {
  width: 80%;
}

.fullcontent {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media (max-width: 800px) {
  .view {
    margin-top: 80px;
    width: 100%;
    box-sizing: border-box;
  }

  .content-view {
    width: 90%;
  }

}
</style>
